.ccFooter{
    background-color: #000;
    color: #fff;
    .box{
        display: flex;
        padding: 4vw;
        padding-bottom: 3vw;
        border-bottom: 1px solid #333;
        .left{
            width: 60%;
            padding-right: 3vw;
            h2{
                font-size: var(--font-size-16);
            }
            ul{
                margin-top: 2vw;
                li{
                    font-size: var(--font-size-12);
                    line-height: 1.5;
                    display: flex;
                    span:first-child{
                        width: 3.2em
                    }
                    span:last-child{
                        width: calc( 100% - 3.2em);
                    }
                }
            }
            
        }
        .right{
            width: 40%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            >div{
                width: 45%;
                p{
                    font-size: var(--font-size-12);
                    text-align: center;
                    white-space: nowrap;
                }
            }
        }
    }
    .ba{
        padding: 2vw 0;
        text-align: center;
        p{
            font-size: var(--font-size-12);
        }
        >div{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 1vw;
            img{
                height: 1em;
            }
            a{
                font-size: var(--font-size-12);
                color: #fff;
                margin-left: 1vw;
            }
        }
    }
}