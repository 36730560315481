*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
li{
  list-style: none;
}
a{
  text-decoration: none;
  color: #333 ;
}
.simple-verify{
  margin: 0 auto;
}
:root:root{
  --adm-color-primary:#008BCF;
  --adm-font-size-9:3.2vw;
  /* 18px */
  --font-size-18:4.8vw;
  /* 16px */
  --font-size-16:4.27vw;
  /* 14px */
  --font-size-14:3.73vw;
  /* 12px */
  --font-size-12:3.2vw;
  /* 10px 最小字体 */
  --font-size-10:2.67vw
}
.adm-swiper-slide{
  height: auto !important;
}