// 全局公用css
.CcxdBtn{
    border: 1px solid var(--adm-color-primary);
    display: flex;
    background-color: transparent;
    align-items: center;
    padding: 1px 10px;
    border-radius: 10vw;
    font-size: var(--font-size-12);
    color: var(--adm-color-primary);
    white-space: nowrap;
    img{
        height: .8em;
        margin-left: .2em;
    }
}