.ccxd_header{
    height:10.6vw;
    background-color: rgba(54,54,54,0.64);
    display: flex;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 3;
    img.menu{
        height: 60%;
    }
    .logo{
        height: 100%;
    }
    :globle{
        .link{
            text-decoration: none;
        }
    }
    a{
        color: #333;
        font-size: var(--font-size-14);
    }
    .active{
        color: var(--adm-color-primary);
    }
    .adm-popup-body{
        overflow-y: auto;
    }
}